var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_vm._m(2),_c('div',[_c('div',{staticClass:"dom2relative"},[_vm._m(3),_c('div',{staticClass:"domUn2 domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(4)])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/县有良品-1w2xmj5l8wmn.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("县有良品SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v(" 该平台是由我们与县学研究院共同打造 旨在“一县一优品，一域一文化”，该平台通过县市国有龙头企业，从产品设计包装到营销推广全链路赋能，加快县域经济数字化、智能化转型，打造县域优质农业品牌，提升县域经济数字化建设水平，促进县域经济数字化转型发展。 ")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})]),_c('div',{staticClass:"domUn domFexidTop"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/县有良品SaaS01-q7stvizjje38.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup wrapper_1200"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domUn2Center"},[_c('div',{staticClass:"topBox"}),_c('div',{staticClass:"domUn2Box"},[_c('div',{staticClass:"img1 animated"},[_c('img',{staticStyle:{"width":"387px","height":"327px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/县有良品SaaS切图2-iqsuelzcywk8.png","alt":""}})]),_c('div',{staticClass:"img2 animated"},[_c('img',{staticStyle:{"width":"937px","height":"975px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/Groupx5516-cswfmmsjzhly.png","alt":""}})])]),_c('div',{staticClass:"tBtmBox"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})]),_c('div',{staticClass:"domUn domFexidTop"},[_c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')]),_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/县有良品SaaS切图3-gcv8cqjc9ajd.png","alt":""}})])])])
}]

export { render, staticRenderFns }