<template>
  <div class="bg">
    <div class="topImg">
      <img
        src="https://oss.pinmallzj.com/image/maintain/2023/09/01/县有良品-1w2xmj5l8wmn.png"
        alt=""
      />
    </div>
    <div style="background: #fff">
      <div class="wrapper_1200 dom1">
        <p class="title">县有良品SaaS</p>
        <p class="titleText">
          <span>
            该平台是由我们与县学研究院共同打造
            旨在“一县一优品，一域一文化”，该平台通过县市国有龙头企业，从产品设计包装到营销推广全链路赋能，加快县域经济数字化、智能化转型，打造县域优质农业品牌，提升县域经济数字化建设水平，促进县域经济数字化转型发展。
          </span>
          <span class="icon1">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png"
              alt=""
            />
          </span>
          <span class="icon2">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png"
              alt=""
            />
          </span>
        </p>
      </div>
    </div>
    <div class="content">
      <div style="background: #fff">
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>
          <div class="domUn domFexidTop">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/县有良品SaaS01-q7stvizjje38.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <div class="dom2relative">
          <div class="domup wrapper_1200">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>

          <div class="domUn2 domFexidTop" :style="`height:` + maxHigth + `px`">
            <div class="domUn2Center">
              <div class="topBox"></div>
              <div class="domUn2Box">
                <div class="img1 animated">
                  <img
                    src="https://oss.pinmallzj.com/image/maintain/2023/09/08/县有良品SaaS切图2-iqsuelzcywk8.png"
                    alt=""
                    style="width: 387px; height: 327px"
                  />
                </div>
                <div class="img2 animated">
                  <img
                    src="https://oss.pinmallzj.com/image/maintain/2023/09/08/Groupx5516-cswfmmsjzhly.png"
                    alt=""
                    style="width: 937px; height: 975px"
                  />
                </div>
              </div>
              <div class="tBtmBox"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff">
        <div class="domrelative wrapper_1200">
          <div class="domup">
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg"
              alt=""
              style="width: 100%; height: 2050px"
            />
          </div>
          <div class="domUn domFexidTop">
            <p>
              <span>核心功能三</span>
              <span></span>
            </p>
            <img
              src="https://oss.pinmallzj.com/image/maintain/2023/09/08/县有良品SaaS切图3-gcv8cqjc9ajd.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ratio: 1.15,
      Y2: 0,
      maxHigth: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.maxHigth = window.screen.availHeight - 90;
    let header = document.querySelector(".header");
    header.style.position = "static";
  },
  methods: {
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //  获取窗口滚动条高度
      this.changeDom2();
    },

    changeDom2() {
      let dom1 = document.querySelector(".dom2relative");
      let dom2 = document.querySelector(".img1");
      let dom3 = document.querySelector(".img2");
      this.Y2 = dom1.offsetTop * this.ratio;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //  获取窗口滚动条高度
      let a = this.Y2 - scrollTop * this.ratio + "";
      if (a < 0) {
        if (a >= -1200) {
          dom2.classList.add("fadeInLeft");
          dom3.classList.add("fadeInRight");
        }
      }
    },
  },
  beforeDestroy() {
    let header = document.querySelector(".header");
    header.style.position = "fiexd";
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>
<style lang="scss" scoped>
.bg {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  .topImg {
    img {
      width: 100%;
      min-height: 500px;
    }
  }
  .dom1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0 80px 0;
    .title {
      color: #333a45;
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 28px;
    }
    .titleText {
      position: relative;
      padding: 0 200px 0 200px;
      color: #485261;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 4px;
      .icon1 {
        position: absolute;
        top: -40px;
        left: 130px;
        width: 39px;
        height: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .icon2 {
        position: absolute;
        bottom: -40px;
        right: 130px;
        width: 39px;
        height: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .content {
    width: 100%;
    background: #eeeef3;
    display: flex;
    flex-direction: column;
    .domrelative {
      position: relative;
      height: 2000px;
      padding-top: 60px;
      .domup {
        position: absolute;
        top: 0;
        z-index: 2;
      }
      .domUn {
        padding: 30px 0 80px 0;
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          margin-top: 60px;
        }
        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            &:nth-child(1) {
              color: #485261;
              text-align: center;
              font-size: 28px;
              font-weight: 500;
              margin-bottom: 15px;
            }
            &:nth-child(2) {
              display: inline-block;
              width: 40px;
              height: 6px;
              background: #288cff;
            }
          }
        }
      }
    }
    .dom2relative {
      position: relative;
      height: 1900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .domup {
        position: absolute;
        top: 0;
        z-index: 2;
      }
      .domUn2 {
        width: 100%;
        position: absolute;
        z-index: 1;
        overflow: hidden;
        .domUn2Center {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;
          .topBox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 150px;
            background: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/Vectorx7-x0rk7tt60s8z.png")
              no-repeat;
            background-size: 105% 100%;
            background-position-x: -14px;
            background-position-y: -10px;
            z-index: 9;
          }
          .domUn2Box {
            width: 100%;
            height: 100%;

            .img1 {
              position: absolute;
              bottom: 250px;
              left: 230px;
            }
            .img2 {
              position: absolute;
              right: -40px;
              top: 0px;
            }
          }
          .tBtmBox {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 150px;
            background: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/Vectorx6-2nvydkgvqn5g.png")
              no-repeat;
            background-size: 105% 110%;
            background-position-x: -10px;
            background-position-y: 2px;
            z-index: 9;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
.domFexidTop {
  position: sticky !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
